<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form
      label-position="right"
      v-if="!field.is_global_variable"
    >
      <title-and-description :field="field" />
      <el-row
        type="flex"
        :gutter="30"
      >
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <!-- <el-row :gutter="50">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Validations">
              <el-date-picker
                type="date"
                placeholder="Min date"
                v-model="field.validations.min"
                style="width: 100%;"
                format="yyyy-dd-MM"
              ></el-date-picker>
              <el-date-picker
                type="date"
                placeholder="Max date"
                v-model="field.validations.max"
                style="width: 100%;"
                format="yyyy-dd-MM"
              ></el-date-picker>
            </el-form-item>
          </div>
        </el-col>
      </el-row>-->
      <el-row
        type="flex"
        :gutter="30"
      >
        <el-col :span="field.data_table_field_index && field.data_table_field_index > -1 ? 8 : 12">
          <field-attributes :field="field" />
        </el-col>
        <el-col :span="8" v-if="field.data_table_field_index && field.data_table_field_index > -1">
          <el-checkbox v-model="field.data_table_field_auto_fill" @change="disableField">Auto Fill</el-checkbox>
          <br/><br/>
          <el-radio-group v-model="field.data_table_field_auto_fill_type" v-if="field.data_table_field_auto_fill" @change="changeType">
            <el-radio label="range">Range</el-radio>
            <el-radio label="custom">Custom</el-radio>
            <el-radio label="frequency">Frequency</el-radio>
          </el-radio-group>
          <br><br>
          <div>
              <el-select v-if="field.data_table_field_auto_fill_type == 'range' && showSelect && field.data_table_field_auto_fill" placeholder="Select Date Range Field" v-model="field.selected_auto_fill_fields.range"  @change="updateDOM" size="mini" style="width : 300px">
              <el-option v-for="(f, index) in otherDateRangeFields" :key="index" :label="f.label" :value="f.key">
              </el-option>
            </el-select>
          </div>
          <div v-if="field.data_table_field_auto_fill_type == 'custom' && field.data_table_field_auto_fill">
            <el-select v-if="showSelect" placeholder="Select From Date" v-model="field.selected_auto_fill_fields.from" @change="updateDOM" size="mini" style="width : 150px">
              <el-option v-for="(f, index) in otherDateFields" :key="index" :label="f.label" :value="f.key" :disabled="f.key == field.selected_auto_fill_fields.to">
              </el-option>
            </el-select>
            To
            <el-select v-if="showSelect" placeholder="Select To Date" v-model="field.selected_auto_fill_fields.to" @change="updateDOM" size="mini" style="width : 150px">
              <el-option v-for="(f, index) in otherDateFields" :key="index" :label="f.label" :value="f.key" :disabled="f.key == field.selected_auto_fill_fields.from">
              </el-option>
            </el-select>
          </div>
          <div v-if="field.data_table_field_auto_fill_type == 'frequency' && field.data_table_field_auto_fill">
            <el-select v-if="showSelect" placeholder="Select From Date" v-model="field.selected_auto_fill_fields.from" @change="updateDOM" size="mini" style="width : 150px">
              <el-option v-for="(f, index) in otherDateFields" :key="index" :label="f.label" :value="f.key" :disabled="f.key == field.selected_auto_fill_fields.to">
              </el-option>
            </el-select>
            To
            <el-input-number size="mini" v-model="field.selected_auto_fill_fields.to" style="width : 80px"></el-input-number>
            <el-select v-model="field.selected_auto_fill_fields.type" size="mini" style="width : 100px; margin-left: 4px">
              <el-option value="days" label="Day(s)"></el-option>
              <el-option value="week" label="Week(s)"></el-option>
              <el-option value="months" label="Month(s)"></el-option>
              <el-option value="years" label="Year(s)"></el-option>
            </el-select>
          </div>
          <br>
          <div v-if="field.data_table_field_auto_fill">
            <span>Step</span>
            <el-input-number size="mini" v-model="field.selected_auto_fill_fields.frequency_step" style="width : 100px; margin-left: 10px"></el-input-number>
            <el-select v-model="field.selected_auto_fill_fields.frequency_type" size="mini" style="width : 150px; margin-left: 4px">
              <el-option value="days" label="Day(s)"></el-option>
              <el-option value="week" label="Week(s)"></el-option>
              <el-option value="months" label="Month(s)"></el-option>
              <el-option value="years" label="Year(s)"></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="field.data_table_field_index && field.data_table_field_index > -1 ? 8 : 12">
          <is-field-required
            :field="field"
            class="field-required"
          />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-select placeholder="Select Date Types" v-model="field.date_view_type">
            <el-option v-for="op in dateTypes" :key="op.value" :label="op.label" :value="op.value"></el-option>

          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="field.data_table_field_auto_fill">
        <el-col>
          Auto Fill Filters
          <el-button @click="addFilter" type="text" class="mr-3">
           Add +
        </el-button>
        <el-radio-group v-model="field.data_table_filter_query">
          <el-radio label="AND"></el-radio>
          <el-radio label="OR"></el-radio>
        </el-radio-group>
        <br>
        <table v-if="showSelect">
          <tr v-for="(filter, f_index) in field.data_table_filters" :key="f_index">
            <td style="margin-top: 2px;">
              <el-select size="mini" placeholder="Select Field type" v-model="filter.type" @change="getOperators(f_index)" clearable>
                <el-option v-for="(type, index) in field_types" :key="index" :label="type.label" :value="type.value" :disabled="disableFieldType(type.value, f_index)">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'DATE' || filter.type == 'WEEKDAYS_SINGLE'" size="mini" placeholder="Select Operator" v-model="filter.operator" style="margin-left: 15px;" clearable> 
                <el-option v-for="(operator, index) in dateOperators" :key="index" :label="operator.label" :value="operator.value">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'DATE_RANGE'" size="mini" placeholder="Select Operator" v-model="filter.operator" style="margin-left: 15px;" clearable> 
                <el-option v-for="(operator, index) in dateRangeOperators" :key="index" :label="operator.label" :value="operator.value">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'WEEKDAYS' || filter.type == 'ENTITY'" size="mini" placeholder="Select Operator" v-model="filter.operator" style="margin-left: 15px;" clearable filterable> 
                <el-option v-for="(operator, index) in weekdaysOperators" :key="index" :label="operator.label" :value="operator.value">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'DATE'" size="mini" placeholder="Select Field" v-model="filter.field" style="margin-left: 15px;" clearable>
                <el-option v-for="(field, index) in otherDateFields" :value="field.key" :key="index" :label="field.label" :disabled="disableFilterFields(field.key,f_index)">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'DATE_RANGE'" size="mini" placeholder="Select Field" v-model="filter.field" style="margin-left: 15px;" clearable>
                <el-option v-for="(field, index) in otherDateRangeFields" :value="field.key" :key="index" :label="field.label" :disabled="disableFilterFields(field.key,f_index)">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'WEEKDAYS'" size="mini" placeholder="Select Field" v-model="filter.field" style="margin-left: 15px;" clearable>
                <el-option v-for="(field, index) in otherWeekdaysFields" :value="field.key" :key="index" :label="field.label" :disabled="disableFilterFields(field.key,f_index)">
                </el-option>
              </el-select>
              <el-select v-if="filter.type == 'WEEKDAYS_SINGLE'" size="mini" placeholder="Select Field" v-model="filter.field" style="margin-left: 15px;" clearable>
                <el-option v-for="(field, index) in otherWeekdaysSingleFields" :value="field.key" :key="index" :label="field.label" :disabled="disableFilterFields(field.key,f_index)">
                </el-option>
              </el-select>
              <el-select v-loading="loading" v-if="filter.type == 'ENTITY'" size="mini" placeholder="Select Entity" v-model="filter.entity_id" style="margin-left: 15px;" clearable @change="changeEntity(f_index)" filterable>
                <el-option v-for="(entity, index) in otherEntities" :value="entity._id" :key="index" :label="entity.name">
                </el-option>
              </el-select>
              <el-select v-loading="loading" v-if="filter.type == 'ENTITY'" size="mini" placeholder="Select Date Field" v-model="filter.field" style="margin-left: 15px" clearable>
                <el-option v-for="(option, index) in filter.field_options" :key="index" :value="option.template_key" :label="option.label">
                </el-option>
              </el-select>
              <el-button type="text" class="mr-3" style="margin-left: 15px;" @click="removeFilter(f_index)">
                Remove
              </el-button>
            </td>
          </tr>
        </table>
        </el-col>
      </el-row>
    </el-form>
    <el-form
      label-position="right"
      v-if="field.is_global_variable"
    >
      <el-row
        type="flex"
        :gutter="30"
      >
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="30"
      >
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required
        :field="field"
        class="field-required"
      />
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EntitiesHelper from "@/mixins/EntitiesHelper"
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";
// import FieldAttributes from "./FieldAttributes";
import templateBuilderHelper from "../../../mixins/templateBuilderHelper";

export default {
  name: "templates-formComponents-Date",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    FieldAttributes: () => import("./FieldAttributes")
  },
  props: ["field","fieldsData"],
  mixins : [EntitiesHelper, templateBuilderHelper],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("entities",["getAllEntities","getEntityDataById"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  data() {
    return {
      dateTypes: 
      [{
        label: 'Year',
        value: 'year'
      },
      {
        label: 'Month',
        value: 'month'
      },
      {
        label: 'Day',
        value: 'day'
      },
    ],
      loading : false,
      validations: [],
      otherDateFields : [],
      otherDateRangeFields : [],
      otherWeekdaysFields: [],
      otherWeekdaysSingleFields : [],
      otherEntities : [],
      showSelect : true,
      operators: [],
      field_types: [{
        label: 'Date',
        value: 'DATE'
      },
      {
        label: 'Date Range',
        value: 'DATE_RANGE'
      },
      {
        label : 'Weekdays (single)',
        value : 'WEEKDAYS_SINGLE'
      },
      {
        label: "Weekdays (multiple)",
        value: 'WEEKDAYS'
      },
      {
        label : 'Entity',
        value : 'ENTITY'
      }],
      dateOperators: [{
        label: 'Equals to',
        value: 'EQUALS'
      },
      {
        label: 'Not Equals to',
        value: 'NOT_EQUALS'
      }],
      dateRangeOperators: [{
        label: 'Between',
        value: 'BETWEEN'
      },
      {
        label: 'Not Between',
        value: 'NOT_BETWEEN'
      }],
      weekdaysOperators: [{
        label: 'Includes',
        value: 'IN'
      },
      {
        label: 'Not Includes',
        value: 'NOT_IN'
      }]
    };
  },
  mounted() {
    if(!this.field.date_view_type){
      this.field.date_view_type = "day"
    }
    this.fetchGlobalVaribales();
    this.fetchFieldsForAutoFill()
  },
  methods: {
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_type: this.field.input_type
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        x => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
    },
    async addFilter(){
      this.field.data_table_filters.push({
        operator : '',
        field : ''
      })
      this.updateDOM()
    },
    async removeFilter(index){
      this.field.data_table_filters.splice(index, 1)
      this.updateDOM()
    },
    async fetchFieldsForAutoFill() {
      this.loading = true
      if (this.field.data_table_field_index && this.field.data_table_field_index > -1) {
        this.otherDateFields = this.fieldsData.filter(e => (e.inputType == 'DATE' || e.result_type == 'DATE') && e.data_table_key == undefined)
        this.otherDateRangeFields = this.fieldsData.filter(e => e.inputType == 'DATE_RANGE' && e.data_table_key == undefined)
        this.otherWeekdaysFields = this.fieldsData.filter(e => e.inputType == 'WEEKDAYS' && e.allow_multiple == true && e.data_table_key == undefined)
        this.otherWeekdaysSingleFields = this.fieldsData.filter(e => e.inputType == 'WEEKDAYS' && e.allow_multiple == false && e.data_table_key == undefined)
        // await this.$store.dispatch("entities/fetchEntities", {
        //   get_all: true,
        // });
        let entitiesData = await this.fetchAllEntities(false);
        if (entitiesData && entitiesData.length) {
          this.otherEntities = entitiesData.filter(e => e && e.status == 'ACTIVE')
        }
      }
      this.loading = false
    },
    updateDOM(){
      this.showSelect = false
      setTimeout(()=>{
        this.showSelect = true
      },10)
    },
    getOperators(index) {
        this.field.data_table_filters[index].field = ''
        this.field.data_table_filters[index].operator = ''
    },
    disableField(){
      if(this.field.data_table_field_auto_fill){
        this.field.properties['filed_content'] = 'DISABLED'
      }
      else{
        this.field.properties['filed_content'] = ''
      }
    },
    async changeEntity(index){
      this.loading = true
      this.field.data_table_filters[index].field = ''
      this.field.data_table_filters[index].operator = ''
      let allFields = await this.fetchEntityDetails(this.field.data_table_filters[index].entity_id, true) 
      this.field.data_table_filters[index]['field_options'] = allFields.filter(e=> e.inputType == 'DATE')
      this.loading = false
    },
    disableFieldType(type, index){
      if(type == 'ENTITY'){
        let entityFilter = this.field.data_table_filters.find((e, i)=> e.type == 'ENTITY' && i !== index )
        if(entityFilter !== undefined){
          return true
        }
        return false
      }
      return false
    },
    disableFilterFields(value, index){
      let field = this.field.data_table_filters.find((e, i)=> e && e.field && e.field == value && i !== index)
      if(field !== undefined){
        return true
      }
      return false
    },
    changeType() {
      this.field.selected_auto_fill_fields = {
        from: '',
        to: '',
        range: '',
        type: 'days',
        frequency_step : 1,
        frequency_type : 'days'
      }
      if(this.field.data_table_field_auto_fill_type == 'frequency'){
        this.field.selected_auto_fill_fields.to = 1
      }
    }
  }
};
</script>

<style lang="scss"></style>
